import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

let prevPathname = '';

export const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);

        // Save the current pathname as the previous pathname for the next render
        prevPathname = pathname;
    }, [pathname]);

    return null;
};

export const usePreviousLocation = () => {
    return prevPathname;
};
