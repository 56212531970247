import {
    MDBCol,
    MDBContainer,
    MDBRow,
    MDBSpinner,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBPagination,
    MDBPaginationItem,
    MDBPaginationLink,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBRadio,
    MDBInput,
    MDBIcon,
} from 'mdb-react-ui-kit';
import React, { useEffect, useState, useCallback } from 'react';
import Select from 'react-select';
import ApiService from 'services/ApiService';
import {
    JobsRequestModel,
    JobListingModel,
    JobsResponseModel,
    JobFiltersResponseModel,
} from 'services/ApiContracts';
import { useNavigate } from 'react-router-dom';
import { selectStyles } from 'utilities/reactSelectStyles';
import 'styles/LookingForWork.css';
import { usePreviousLocation } from 'utilities/scrollToTopHook';
import { delay } from 'utilities/delay';
import queryString from 'query-string';
import BuildProfileDialog from 'components/BuildProfileDialog';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';

const apiService = new ApiService();

export interface ILookingForWorkPageProps {}

const LookingForWorkPage: React.FunctionComponent<ILookingForWorkPageProps> = (
    props,
) => {
    const [jobTypes, setJobTypes] = useState<string[]>([]);
    const [states, setStates] = useState<string[]>([]);
    const [professions, setProfessions] = useState<string[]>([]);
    const [specialties, setSpecialties] = useState<string[]>([]);
    const [categories, setCategories] = useState<string[]>([]);
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [jobs, setJobs] = useState<JobListingModel[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [noJobsFound, setNoJobsFound] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [totalJobs, setTotalJobs] = useState<number>(0);
    const [filterOptions, setFilterOptions] = useState<JobFiltersResponseModel>(
        {
            categories: [],
            professions: [],
            specialties: [],
            states: [],
            jobTypes: [],
        },
    );
    const [sortValue, setSortValue] = useState<string>('weeklyPay');
    const [appliedFilters, setAppliedFilters] = useState<{
        jobTypes: string[];
        states: string[];
        professions: string[];
        specialties: string[];
        categories: string[];
        sortValue: string;
        searchFilter: string;
    }>({
        jobTypes: [],
        states: [],
        professions: [],
        specialties: [],
        categories: [],
        sortValue: 'weeklyPay',
        searchFilter: '',
    });
    const [showModal, setShowModal] = useState<boolean>(false);
    const prevLocation = usePreviousLocation();
    const navigate = useNavigate();

    const fetchJobFilters = async () => {
        try {
            const filters = await apiService.jobFilters();
            setFilterOptions(filters);
        } catch (error) {
            console.error('Failed to fetch job filters:', error);
        }
    };

    const fetchJobs = async (
        filters: JobsRequestModel,
        scrollPosition?: string | null | undefined,
    ) => {
        setLoading(true);
        setNoJobsFound(false);
        try {
            const response: JobsResponseModel = await apiService.listJobs({
                ...filters,
            });
            const jobs = response.jobs || [];
            setJobs(jobs);
            setNoJobsFound(jobs.length === 0);
            setTotalPages(response.totalPages || 1);
            setTotalJobs(response.totalJobs);

            setAppliedFilters({
                jobTypes: filters.jobTypes,
                states: filters.states,
                professions: filters.professions,
                specialties: filters.specialties,
                categories: filters.categories,
                sortValue: filters.sortValue,
                searchFilter: filters.searchFilter,
            });

            await delay(100);
            setTimeout(() => {
                window.scrollTo(0, parseInt(scrollPosition ?? '0', 10));
            }, 100);
        } catch (error) {
            console.error('Failed to fetch jobs:', error);
            setNoJobsFound(true);
        } finally {
            setLoading(false);
        }
    };

    const updateURLWithFilters = (filters: JobsRequestModel) => {
        const filteredQuery = Object.fromEntries(
            Object.entries({
                jobTypes:
                    filters.jobTypes.length > 0
                        ? filters.jobTypes.join(',')
                        : undefined,
                states:
                    filters.states.length > 0
                        ? filters.states.join(',')
                        : undefined,
                professions:
                    filters.professions.length > 0
                        ? filters.professions.join(',')
                        : undefined,
                specialties:
                    filters.specialties.length > 0
                        ? filters.specialties.join(',')
                        : undefined,
                categories:
                    filters.categories.length > 0
                        ? filters.categories.join(',')
                        : undefined,
                sortValue:
                    filters.sortValue !== 'weeklyPay'
                        ? filters.sortValue
                        : undefined,
                searchFilter: filters.searchFilter || undefined,
            }).filter(([, value]) => value !== undefined),
        );
        const query = queryString.stringify(filteredQuery);
        navigate(`?${query}`);
    };

    useEffect(() => {
        fetchJobFilters();

        const queryParams = new URLSearchParams(window.location.search);

        // Determine if any query parameters are present
        const hasQueryParams =
            queryParams.has('jobTypes') ||
            queryParams.has('states') ||
            queryParams.has('professions') ||
            queryParams.has('specialties') ||
            queryParams.has('categories') ||
            queryParams.has('sortValue') ||
            queryParams.has('searchFilter');

        const initialJobTypes =
            hasQueryParams && queryParams.get('jobTypes')
                ? queryParams.get('jobTypes')!.split(',')
                : !hasQueryParams && sessionStorage.getItem('jobs-jobTypes')
                ? JSON.parse(sessionStorage.getItem('jobs-jobTypes')!)
                : [];

        const initialStates =
            hasQueryParams && queryParams.get('states')
                ? queryParams.get('states')!.split(',')
                : !hasQueryParams && sessionStorage.getItem('jobs-states')
                ? JSON.parse(sessionStorage.getItem('jobs-states')!)
                : [];

        const initialProfessions =
            hasQueryParams && queryParams.get('professions')
                ? queryParams.get('professions')!.split(',')
                : !hasQueryParams && sessionStorage.getItem('jobs-professions')
                ? JSON.parse(sessionStorage.getItem('jobs-professions')!)
                : [];

        const initialSpecialties =
            hasQueryParams && queryParams.get('specialties')
                ? queryParams.get('specialties')!.split(',')
                : !hasQueryParams && sessionStorage.getItem('jobs-specialties')
                ? JSON.parse(sessionStorage.getItem('jobs-specialties')!)
                : [];

        const initialCategories =
            hasQueryParams && queryParams.get('categories')
                ? queryParams.get('categories')!.split(',')
                : !hasQueryParams && sessionStorage.getItem('jobs-categories')
                ? JSON.parse(sessionStorage.getItem('jobs-categories')!)
                : [];

        const initialSortValue =
            hasQueryParams && queryParams.get('sortValue')
                ? queryParams.get('sortValue')!
                : !hasQueryParams && sessionStorage.getItem('jobs-sortValue')
                ? sessionStorage.getItem('jobs-sortValue')!
                : 'weeklyPay';

        const initialSearchFilter =
            hasQueryParams && queryParams.get('searchFilter')
                ? queryParams.get('searchFilter')!
                : !hasQueryParams && sessionStorage.getItem('jobs-searchFilter')
                ? sessionStorage.getItem('jobs-searchFilter')!
                : '';

        // page number is not included in the query params, so read directly from storage.
        const storedPageNumber = sessionStorage.getItem('jobs-pageNum');
        const comingFromJobDetails =
            prevLocation && prevLocation.startsWith('/job/');
        const initialPageNum =
            storedPageNumber && comingFromJobDetails
                ? JSON.parse(storedPageNumber)
                : 1;

        if (hasQueryParams) {
            // Update session storage with query parameters if they exist
            sessionStorage.setItem(
                'jobs-jobTypes',
                JSON.stringify(initialJobTypes),
            );
            sessionStorage.setItem(
                'jobs-states',
                JSON.stringify(initialStates),
            );
            sessionStorage.setItem(
                'jobs-professions',
                JSON.stringify(initialProfessions),
            );
            sessionStorage.setItem(
                'jobs-specialties',
                JSON.stringify(initialSpecialties),
            );
            sessionStorage.setItem(
                'jobs-categories',
                JSON.stringify(initialCategories),
            );
            sessionStorage.setItem('jobs-sortValue', initialSortValue);
            sessionStorage.setItem('jobs-searchFilter', initialSearchFilter);
            sessionStorage.setItem(
                'jobs-pageNum',
                JSON.stringify(initialPageNum),
            );
        }

        setJobTypes(initialJobTypes);
        setStates(initialStates);
        setProfessions(initialProfessions);
        setSpecialties(initialSpecialties);
        setCategories(initialCategories);
        setSortValue(initialSortValue);
        setSearchFilter(initialSearchFilter);
        setCurrentPage(initialPageNum);

        const defaultScrollPosition = comingFromJobDetails
            ? sessionStorage.getItem('jobs-scrollPosition')
            : undefined;

        const filters: JobsRequestModel = {
            pageNumber: initialPageNum,
            sortValue: initialSortValue,
            jobTypes: initialJobTypes,
            states: initialStates,
            professions: initialProfessions,
            specialties: initialSpecialties,
            categories: initialCategories,
            searchFilter: initialSearchFilter,
        };

        updateURLWithFilters(filters);
        fetchJobs(filters, defaultScrollPosition);

        // Read 'buildProfile' parameter from URL
        const buildProfile = queryParams.get('buildProfile');

        if (buildProfile === 'true') {
            setShowModal(false); // Ensure showModal is false
            toggleModal(); // Open the dialog
        }
    }, []); // Run only once on mount

    const handleJobTypeChange = (selectedOptions: any) => {
        setJobTypes(selectedOptions.map((option: any) => option.value));
    };

    const handleStateChange = (selectedOptions: any) => {
        setStates(selectedOptions.map((option: any) => option.value));
    };

    const handleProfessionChange = (selectedOptions: any) => {
        setProfessions(selectedOptions.map((option: any) => option.value));
    };

    const handleSpecialtyChange = (selectedOptions: any) => {
        setSpecialties(selectedOptions.map((option: any) => option.value));
    };

    const handleCategoryChange = (selectedOptions: any) => {
        setCategories(selectedOptions.map((option: any) => option.value));
    };

    const handleSortChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSortValue(event.target.value);
    };

    const handleSearchFilterChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setSearchFilter(event.target.value);
    };

    const handleSearch = () => {
        sessionStorage.setItem('jobs-jobTypes', JSON.stringify(jobTypes));
        sessionStorage.setItem('jobs-states', JSON.stringify(states));
        sessionStorage.setItem('jobs-professions', JSON.stringify(professions));
        sessionStorage.setItem('jobs-specialties', JSON.stringify(specialties));
        sessionStorage.setItem('jobs-categories', JSON.stringify(categories));
        sessionStorage.setItem('jobs-sortValue', sortValue);
        sessionStorage.setItem('jobs-searchFilter', searchFilter);

        // New search takes user back to the first page.
        sessionStorage.setItem('jobs-pageNum', JSON.stringify(1));
        setCurrentPage(1);

        // Facebook tracking.
        if (window.fbq) {
            const contents: { type: string; value: string }[] = [];

            if (jobTypes.length > 0) {
                contents.push({
                    type: 'jobTypes',
                    value: jobTypes.join(','),
                });
            }

            if (states.length > 0) {
                contents.push({
                    type: 'states',
                    value: states.join(','),
                });
            }

            if (professions.length > 0) {
                contents.push({
                    type: 'professions',
                    value: professions.join(','),
                });
            }

            if (specialties.length > 0) {
                contents.push({
                    type: 'specialties',
                    value: specialties.join(','),
                });
            }

            if (categories.length > 0) {
                contents.push({
                    type: 'categories',
                    value: categories.join(','),
                });
            }

            if (sortValue) {
                contents.push({
                    type: 'sortValue',
                    value: sortValue,
                });
            }

            if (searchFilter) {
                contents.push({
                    type: 'searchFilter',
                    value: searchFilter,
                });
            }

            window.fbq('track', 'Search', {
                search_string: searchFilter,
                contents: contents,
            });
        }

        const filters: JobsRequestModel = {
            pageNumber: 1,
            sortValue: sortValue,
            jobTypes: jobTypes,
            states: states,
            professions: professions,
            specialties: specialties,
            categories: categories,
            searchFilter: searchFilter,
        };

        updateURLWithFilters(filters);
        fetchJobs(filters);
    };

    const handleViewDetails = (jobId: number) => {
        sessionStorage.setItem(
            'jobs-scrollPosition',
            window.scrollY.toString(),
        );
        navigate(`/job/${jobId}`);
    };

    const handlePageChange = (page: number) => {
        sessionStorage.setItem('jobs-pageNum', JSON.stringify(page));
        setCurrentPage(page);
        fetchJobs({
            pageNumber: page,
            sortValue: sortValue,
            jobTypes: jobTypes,
            states: states,
            professions: professions,
            specialties: specialties,
            categories: categories,
            searchFilter: searchFilter,
        });
    };

    const renderColumnValues = (columnValues: { [key: string]: string }) => {
        return Object.entries(columnValues).map(([key, value]) => {
            if (key.startsWith('Break')) {
                return <br key={key} />;
            }
            return (
                <div key={key} className="text-muted">
                    <strong>{key}:</strong> {value}
                </div>
            );
        });
    };

    const haveFiltersChanged = useCallback(() => {
        return (
            JSON.stringify(appliedFilters.jobTypes) !==
                JSON.stringify(jobTypes) ||
            JSON.stringify(appliedFilters.states) !== JSON.stringify(states) ||
            JSON.stringify(appliedFilters.professions) !==
                JSON.stringify(professions) ||
            JSON.stringify(appliedFilters.specialties) !==
                JSON.stringify(specialties) ||
            JSON.stringify(appliedFilters.categories) !==
                JSON.stringify(categories) ||
            appliedFilters.sortValue !== sortValue ||
            appliedFilters.searchFilter !== searchFilter
        );
    }, [
        appliedFilters,
        jobTypes,
        states,
        professions,
        specialties,
        categories,
        sortValue,
        searchFilter,
    ]);

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    return (
        <MDBContainer fluid className="page-padding">
            {/* Build Profile Dialog */}
            <BuildProfileDialog
                showModal={showModal}
                setShowModal={setShowModal}
            />

            {/* Build Profile Button */}
            <MDBRow className="mb-4">
                <MDBCol className="text-left">
                    <MDBBtn color="secondary" onClick={toggleModal}>
                        <MDBIcon fas icon="user-plus" className="me-2" />
                        Build Clinician Profile
                    </MDBBtn>
                </MDBCol>
            </MDBRow>

            {/* Search Jobs Row */}
            <MDBRow className="text-center">
                <MDBCol>
                    <h2 className="display-4">Search Jobs</h2>
                    <hr className="my-4 centered-hr" />
                </MDBCol>
            </MDBRow>

            <div className="filter-sort-container">
                <MDBRow className="text-center mb-4">
                    <strong>
                        <em>
                            Looking for a specific role? Select your options and
                            press Apply Filters.
                        </em>
                    </strong>
                </MDBRow>

                {/* Filtering Row */}
                <MDBRow>
                    <MDBCol md="4" size="12" className="mb-3">
                        <Select
                            isMulti
                            options={filterOptions.categories}
                            placeholder="Job Categories"
                            onChange={handleCategoryChange}
                            value={filterOptions.categories.filter((category) =>
                                categories.includes(category.value),
                            )}
                            styles={selectStyles}
                        />
                    </MDBCol>
                    <MDBCol md="4" size="12" className="mb-3">
                        <Select
                            isMulti
                            options={filterOptions.professions}
                            placeholder="Professions"
                            onChange={handleProfessionChange}
                            value={filterOptions.professions.filter(
                                (profession) =>
                                    professions.includes(profession.value),
                            )}
                            styles={selectStyles}
                        />
                    </MDBCol>
                    <MDBCol md="4" size="12" className="mb-3">
                        <Select
                            isMulti
                            options={filterOptions.jobTypes}
                            placeholder="Job Types"
                            onChange={handleJobTypeChange}
                            value={filterOptions.jobTypes.filter((jobType) =>
                                jobTypes.includes(jobType.value),
                            )}
                            styles={selectStyles}
                        />
                    </MDBCol>
                </MDBRow>

                <MDBRow>
                    <MDBCol md="4" size="12" className="mb-3">
                        <Select
                            isMulti
                            options={filterOptions.states}
                            placeholder="States"
                            onChange={handleStateChange}
                            value={filterOptions.states.filter((state) =>
                                states.includes(state.value),
                            )}
                            styles={selectStyles}
                        />
                    </MDBCol>
                    <MDBCol md="4" size="12" className="mb-3">
                        <Select
                            isMulti
                            options={filterOptions.specialties}
                            placeholder="Specialties"
                            onChange={handleSpecialtyChange}
                            value={filterOptions.specialties.filter(
                                (specialty) =>
                                    specialties.includes(specialty.value),
                            )}
                            styles={selectStyles}
                        />
                    </MDBCol>
                    <MDBCol md="4" size="12" className="mb-3">
                        <MDBInput
                            label="Keyword Search"
                            value={searchFilter}
                            onChange={handleSearchFilterChange}
                        />
                    </MDBCol>
                </MDBRow>

                {/* Sorting Row */}
                <MDBRow className="mb-3 align-items-center">
                    <MDBCol className="align-items-center">
                        <MDBRadio
                            name="sortRadio"
                            id="weeklyPay"
                            value="weeklyPay"
                            label="Sort by highest paying"
                            onChange={handleSortChange}
                            checked={sortValue === 'weeklyPay'}
                            inline
                        />
                        <MDBRadio
                            name="sortRadio"
                            id="datePosted"
                            value="datePosted"
                            label="Sort by newest"
                            onChange={handleSortChange}
                            checked={sortValue === 'datePosted'}
                            inline
                        />
                    </MDBCol>
                </MDBRow>

                {/* Apply Button Row */}
                <MDBRow className="text-center">
                    <MDBCol>
                        <MDBBtn
                            onClick={handleSearch}
                            color="primary"
                            className="apply-button"
                            disabled={!haveFiltersChanged()}
                        >
                            Apply Filters
                        </MDBBtn>
                    </MDBCol>
                </MDBRow>
            </div>

            {/* Total Jobs Display */}
            {!loading && !noJobsFound && (
                <MDBRow>
                    <MDBCol className="mb-3">
                        <strong>Available Jobs:</strong>&nbsp;
                        {totalJobs.toLocaleString()}
                    </MDBCol>
                </MDBRow>
            )}

            {/* Jobs list and/or loading spinner section. */}
            <MDBRow>
                {loading ? (
                    <MDBCol className="text-center">
                        <MDBSpinner
                            size="lg"
                            color="primary"
                            style={{ marginTop: 24, marginBottom: 24 }}
                        />
                    </MDBCol>
                ) : noJobsFound ? (
                    <MDBCol className="text-center">
                        <p className="no-jobs-found">
                            No jobs found. Try adjusting the filters above to
                            explore more opportunities, or click 'Build
                            Clinician Profile' — we'll help you find the perfect
                            match for your skills and preferences!
                        </p>
                    </MDBCol>
                ) : (
                    jobs.map((job) => (
                        <MDBRow key={job.jobId} className="mb-4">
                            <MDBCard
                                className="job-listing"
                                onClick={() => handleViewDetails(job.jobId)}
                                style={{ cursor: 'pointer' }}
                            >
                                <MDBCardHeader className="bg-primary text-white">
                                    <h5 className="mb-0">{job.header}</h5>
                                </MDBCardHeader>
                                <MDBCardBody>
                                    <MDBRow className="mb-4">
                                        <MDBCol
                                            md={
                                                job.latitude && job.longitude
                                                    ? '5'
                                                    : '6'
                                            }
                                            className="mb-3 mb-md-0"
                                        >
                                            {renderColumnValues(
                                                job.columnOneDisplayValues,
                                            )}
                                        </MDBCol>
                                        <MDBCol
                                            md={
                                                job.latitude && job.longitude
                                                    ? '5'
                                                    : '6'
                                            }
                                            className="mb-3 mb-md-0"
                                        >
                                            {renderColumnValues(
                                                job.columnTwoDisplayValues,
                                            )}
                                        </MDBCol>
                                        {job.latitude && job.longitude && (
                                            <MDBCol md="2">
                                                <MapContainer
                                                    center={[
                                                        job.latitude,
                                                        job.longitude,
                                                    ]}
                                                    zoom={5}
                                                    style={{
                                                        height: '200px',
                                                        width: '200px',
                                                    }}
                                                    scrollWheelZoom={false}
                                                    doubleClickZoom={false}
                                                    touchZoom={false}
                                                    zoomControl={false}
                                                    dragging={false}
                                                >
                                                    <TileLayer
                                                        attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                    />
                                                    <Marker
                                                        position={[
                                                            job.latitude,
                                                            job.longitude,
                                                        ]}
                                                    ></Marker>
                                                </MapContainer>
                                            </MDBCol>
                                        )}
                                    </MDBRow>
                                    <MDBRow className="text-center">
                                        <MDBBtn
                                            color="secondary"
                                            style={{ width: '200px' }}
                                            onClick={() =>
                                                handleViewDetails(job.jobId)
                                            }
                                        >
                                            View Details
                                        </MDBBtn>
                                    </MDBRow>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBRow>
                    ))
                )}
            </MDBRow>

            {/* Pagination */}
            {!loading && !noJobsFound && totalPages !== 1 && (
                <MDBRow className="mt-4">
                    <MDBPagination className="pagination-center">
                        <MDBPaginationItem disabled={currentPage === 1}>
                            <MDBPaginationLink
                                onClick={() =>
                                    handlePageChange(currentPage - 1)
                                }
                            >
                                Previous Page
                            </MDBPaginationLink>
                        </MDBPaginationItem>

                        <MDBDropdown>
                            <MDBDropdownToggle caret color="secondary">
                                Page {currentPage}
                            </MDBDropdownToggle>
                            <MDBDropdownMenu>
                                {Array.from({ length: totalPages }, (_, i) => (
                                    <MDBDropdownItem
                                        key={i + 1}
                                        active={currentPage === i + 1}
                                        onClick={() => handlePageChange(i + 1)}
                                        className="text-center"
                                    >
                                        {i + 1}
                                    </MDBDropdownItem>
                                ))}
                            </MDBDropdownMenu>
                        </MDBDropdown>

                        <MDBPaginationItem
                            disabled={currentPage === totalPages}
                        >
                            <MDBPaginationLink
                                onClick={() =>
                                    handlePageChange(currentPage + 1)
                                }
                            >
                                Next Page
                            </MDBPaginationLink>
                        </MDBPaginationItem>
                    </MDBPagination>
                </MDBRow>
            )}
        </MDBContainer>
    );
};

export default LookingForWorkPage;
