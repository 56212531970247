import React, { useState, useEffect } from 'react';
import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBBtn,
    MDBInput,
    MDBFile,
    MDBSpinner,
    MDBTextArea,
    MDBRow,
    MDBCol,
} from 'mdb-react-ui-kit';
import Select from 'react-select';
import ApiService from 'services/ApiService';
import {
    BuildProfileRequestModel,
    UploadDocumentsResponseModel,
} from 'services/ApiContracts';
import { BlockBlobClient } from '@azure/storage-blob';
import { convertFileToArrayBuffer } from 'utilities/uploadHelpers';
import { selectStyles } from 'utilities/reactSelectStyles';
import logo from 'assets/logo50.png';

interface BuildProfileDialogProps {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
}

const apiService = new ApiService();

const BuildProfileDialog: React.FC<BuildProfileDialogProps> = ({
    showModal,
    setShowModal,
}) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [formError, setFormError] = useState<string | null>(null);
    const [formSuccess, setFormSuccess] = useState<boolean>(false);

    interface BuildProfileFormData {
        name: string;
        email: string;
        phone: string;
        profession: string;
        specialty?: string;
        yearsOfExperience?: string;
        notes?: string;
        desiredStates: string[];
        desiredJobTypes: string[];
    }

    const [formData, setFormData] = useState<BuildProfileFormData>({
        name: '',
        email: '',
        phone: '',
        profession: '',
        specialty: '',
        yearsOfExperience: '',
        notes: '',
        desiredStates: [],
        desiredJobTypes: [],
    });

    const [resumeFiles, setResumeFiles] = useState<FileList | null>(null);
    const [blsFiles, setBlsFiles] = useState<FileList | null>(null);
    const [nursingLicenseFiles, setNursingLicenseFiles] =
        useState<FileList | null>(null);
    const [referencesFiles, setReferencesFiles] = useState<FileList | null>(
        null,
    );
    const [otherFiles, setOtherFiles] = useState<FileList | null>(null);

    // Options for the states and job types
    const stateOptions = [
        { value: 'All', label: 'All States' },
        { value: 'AL', label: 'Alabama' },
        { value: 'AZ', label: 'Arizona' },
        { value: 'AR', label: 'Arkansas' },
        { value: 'CA', label: 'California' },
        { value: 'CO', label: 'Colorado' },
        { value: 'CT', label: 'Connecticut' },
        { value: 'DE', label: 'Delaware' },
        { value: 'FL', label: 'Florida' },
        { value: 'GA', label: 'Georgia' },
        { value: 'ID', label: 'Idaho' },
        { value: 'IL', label: 'Illinois' },
        { value: 'IN', label: 'Indiana' },
        { value: 'IA', label: 'Iowa' },
        { value: 'KS', label: 'Kansas' },
        { value: 'KY', label: 'Kentucky' },
        { value: 'LA', label: 'Louisiana' },
        { value: 'ME', label: 'Maine' },
        { value: 'MD', label: 'Maryland' },
        { value: 'MA', label: 'Massachusetts' },
        { value: 'MI', label: 'Michigan' },
        { value: 'MN', label: 'Minnesota' },
        { value: 'MS', label: 'Mississippi' },
        { value: 'MO', label: 'Missouri' },
        { value: 'MT', label: 'Montana' },
        { value: 'NE', label: 'Nebraska' },
        { value: 'NV', label: 'Nevada' },
        { value: 'NH', label: 'New Hampshire' },
        { value: 'NJ', label: 'New Jersey' },
        { value: 'NM', label: 'New Mexico' },
        { value: 'NY', label: 'New York' },
        { value: 'NC', label: 'North Carolina' },
        { value: 'ND', label: 'North Dakota' },
        { value: 'OH', label: 'Ohio' },
        { value: 'OK', label: 'Oklahoma' },
        { value: 'OR', label: 'Oregon' },
        { value: 'PA', label: 'Pennsylvania' },
        { value: 'RI', label: 'Rhode Island' },
        { value: 'SC', label: 'South Carolina' },
        { value: 'SD', label: 'South Dakota' },
        { value: 'TN', label: 'Tennessee' },
        { value: 'TX', label: 'Texas' },
        { value: 'UT', label: 'Utah' },
        { value: 'VT', label: 'Vermont' },
        { value: 'VA', label: 'Virginia' },
        { value: 'WA', label: 'Washington' },
        { value: 'WV', label: 'West Virginia' },
        { value: 'WI', label: 'Wisconsin' },
        { value: 'WY', label: 'Wyoming' },
    ];

    const jobTypeOptions = [
        { value: 'Permanent', label: 'Permanent' },
        { value: 'Travel', label: 'Travel' },
    ];

    // Reset form when modal opens
    useEffect(() => {
        if (showModal) {
            setFormData({
                name: '',
                email: '',
                phone: '',
                profession: '',
                specialty: '',
                yearsOfExperience: '',
                notes: '',
                desiredStates: [],
                desiredJobTypes: [],
            });
            setResumeFiles(null);
            setBlsFiles(null);
            setNursingLicenseFiles(null);
            setReferencesFiles(null);
            setOtherFiles(null);
            setFormError(null);
            setFormSuccess(false);
        }
    }, [showModal]);

    const closeModal = () => {
        setShowModal(false);

        setFormData({
            name: '',
            email: '',
            phone: '',
            profession: '',
            specialty: '',
            yearsOfExperience: '',
            notes: '',
            desiredStates: [],
            desiredJobTypes: [],
        });
    };

    const handleFormInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleFormTextareaChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, files } = e.target;
        switch (id) {
            case 'resumeFiles':
                setResumeFiles(files);
                break;
            case 'blsFiles':
                setBlsFiles(files);
                break;
            case 'nursingLicenseFiles':
                setNursingLicenseFiles(files);
                break;
            case 'referencesFiles':
                setReferencesFiles(files);
                break;
            case 'otherFiles':
                setOtherFiles(files);
                break;
            default:
                break;
        }
    };

    const handleDesiredStatesChange = (selectedOptions: any) => {
        setFormData({
            ...formData,
            desiredStates: selectedOptions
                ? selectedOptions.map((option: { value: any }) => option.value)
                : [],
        });
    };

    const handleDesiredJobTypeChange = (selectedOptions: any) => {
        setFormData({
            ...formData,
            desiredJobTypes: selectedOptions
                ? selectedOptions.map((option: { value: any }) => option.value)
                : [],
        });
    };

    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFormError(null);
        setFormSuccess(false);
        setIsSubmitting(true);

        try {
            let uploadId: string | undefined;
            const allFiles: File[] = [];

            if (resumeFiles) {
                allFiles.push(...Array.from(resumeFiles));
            }
            if (blsFiles) {
                allFiles.push(...Array.from(blsFiles));
            }
            if (nursingLicenseFiles) {
                allFiles.push(...Array.from(nursingLicenseFiles));
            }
            if (referencesFiles) {
                allFiles.push(...Array.from(referencesFiles));
            }
            if (otherFiles) {
                allFiles.push(...Array.from(otherFiles));
            }

            // Check required fields
            if (
                !formData.name ||
                !formData.email ||
                !formData.phone ||
                !formData.profession ||
                !formData.desiredStates ||
                formData.desiredStates.length === 0 ||
                !formData.desiredJobTypes ||
                formData.desiredJobTypes.length === 0
            ) {
                setFormError(
                    'Please fill out all required fields marked with *.',
                );
                setIsSubmitting(false);
                return;
            }

            if (allFiles.length > 0) {
                // Ensure unique filenames
                const fileNameCounts: { [key: string]: number } = {};
                const uniqueFiles: { file: File; uniqueName: string }[] = [];

                for (const file of allFiles) {
                    let fileName = file.name;
                    if (fileNameCounts[fileName]) {
                        const count = ++fileNameCounts[fileName];
                        const dotIndex = fileName.lastIndexOf('.');
                        const name =
                            dotIndex !== -1
                                ? fileName.substring(0, dotIndex)
                                : fileName;
                        const extension =
                            dotIndex !== -1 ? fileName.substring(dotIndex) : '';
                        fileName = `${name}(${count})${extension}`;
                    } else {
                        fileNameCounts[fileName] = 1;
                    }
                    uniqueFiles.push({ file, uniqueName: fileName });
                }

                const fileNames = uniqueFiles.map((item) => item.uniqueName);
                const uploadRequest = {
                    uploaderName: formData.name,
                    fileNames,
                    submissionType: 'BuildProfile',
                };

                const uploadResponse: UploadDocumentsResponseModel =
                    await apiService.uploadDocuments(uploadRequest);

                const promises = uniqueFiles.map(async (item) => {
                    try {
                        const sasUri = uploadResponse.sasUris[item.uniqueName];
                        if (!sasUri) {
                            return;
                        }

                        const fileData = await convertFileToArrayBuffer(
                            item.file,
                        );
                        if (!fileData) {
                            return;
                        }

                        const blockBlobClient = new BlockBlobClient(sasUri);
                        await blockBlobClient.uploadData(fileData);
                    } catch (err) {
                        console.error(
                            `Error uploading file ${item.uniqueName}:`,
                            err,
                        );
                    }
                });

                await Promise.all(promises);
                uploadId = uploadResponse.uploadId;
            }

            const buildProfileRequest: BuildProfileRequestModel = {
                ...formData,
                uploadId,
            };

            await apiService.buildProfile(buildProfileRequest);
            setFormSuccess(true);

            // Meta pixel event tracking.
            if (window.fbq) {
                window.fbq('track', 'SubmitApplication', {
                    content_name: 'build_profile_submitted',
                    documents_included: allFiles.length > 0,
                    resume_included: resumeFiles && resumeFiles.length > 0,
                    bls_included: blsFiles && blsFiles.length > 0,
                    nursing_license_included:
                        nursingLicenseFiles && nursingLicenseFiles.length > 0,
                    references_included:
                        referencesFiles && referencesFiles.length > 0,
                    other_documents_included:
                        otherFiles && otherFiles.length > 0,
                });
            }
        } catch (err) {
            setFormError(
                "Something went wrong. Please try again, and if the problem persists, contact us at <a href='mailto:info@x-staffing.com'>info@x-staffing.com</a>.",
            );
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <MDBModal open={showModal} setOpen={setShowModal} tabIndex={-1}>
            <MDBModalDialog size="lg" scrollable>
                <MDBModalContent>
                    <MDBModalHeader className="modal-header d-flex align-items-center">
                        <img src={logo} alt="Logo" className="me-3" />
                        <div className="flex-grow-1">
                            <MDBModalTitle>Build Your Profile</MDBModalTitle>
                            <em>
                                Tell us about yourself, and we will reach out
                                ASAP!
                            </em>
                        </div>
                        <MDBBtn
                            className="btn-close btn-close-white"
                            color="none"
                            onClick={closeModal}
                        ></MDBBtn>
                    </MDBModalHeader>

                    <MDBModalBody className="modal-body">
                        {!formSuccess ? (
                            <form onSubmit={handleFormSubmit}>
                                {/* First Row: Name and Email */}
                                <MDBRow>
                                    <MDBCol md="6" size="12">
                                        <MDBInput
                                            wrapperClass="mb-3"
                                            id="name"
                                            label="Name *"
                                            value={formData.name}
                                            onChange={handleFormInputChange}
                                            required
                                        />
                                    </MDBCol>
                                    <MDBCol md="6" size="12">
                                        <MDBInput
                                            wrapperClass="mb-3"
                                            type="email"
                                            id="email"
                                            label="Email *"
                                            value={formData.email}
                                            onChange={handleFormInputChange}
                                            required
                                        />
                                    </MDBCol>
                                </MDBRow>

                                {/* Second Row: Phone and Profession */}
                                <MDBRow>
                                    <MDBCol md="6" size="12">
                                        <MDBInput
                                            wrapperClass="mb-3"
                                            type="tel"
                                            id="phone"
                                            label="Phone *"
                                            value={formData.phone}
                                            onChange={handleFormInputChange}
                                            required
                                        />
                                    </MDBCol>
                                    <MDBCol md="6" size="12">
                                        <MDBInput
                                            wrapperClass="mb-3"
                                            id="profession"
                                            label="Profession *"
                                            value={formData.profession}
                                            onChange={handleFormInputChange}
                                            required
                                        />
                                    </MDBCol>
                                </MDBRow>

                                {/* Third Row: Specialty */}
                                <MDBRow>
                                    <MDBCol md="6" size="12">
                                        <MDBInput
                                            wrapperClass="mb-3"
                                            id="specialty"
                                            label="Specialty(s)"
                                            value={formData.specialty}
                                            onChange={handleFormInputChange}
                                        />
                                    </MDBCol>
                                    <MDBCol md="6" size="12">
                                        <MDBInput
                                            wrapperClass="mb-3"
                                            id="yearsOfExperience"
                                            label="Years of Experience"
                                            value={formData.yearsOfExperience}
                                            onChange={handleFormInputChange}
                                        />
                                    </MDBCol>
                                </MDBRow>

                                {/* Fourth Row: Desired States and Desired Job Types */}
                                <MDBRow>
                                    <MDBCol md="6" size="12">
                                        <div className="mb-3">
                                            <label htmlFor="desiredStates">
                                                Desired States *
                                            </label>
                                            <Select
                                                id="desiredStates"
                                                isMulti
                                                options={stateOptions}
                                                styles={selectStyles}
                                                placeholder="Select desired state(s)"
                                                onChange={
                                                    handleDesiredStatesChange
                                                }
                                                value={stateOptions.filter(
                                                    (state) =>
                                                        formData.desiredStates.includes(
                                                            state.value,
                                                        ),
                                                )}
                                            />
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="6" size="12">
                                        <div className="mb-3">
                                            <label htmlFor="desiredJobType">
                                                Desired Job Type *
                                            </label>
                                            <Select
                                                id="desiredJobType"
                                                isMulti
                                                options={jobTypeOptions}
                                                styles={selectStyles}
                                                placeholder="Select desired job type(s)"
                                                onChange={
                                                    handleDesiredJobTypeChange
                                                }
                                                value={jobTypeOptions.filter(
                                                    (jobType) =>
                                                        formData.desiredJobTypes.includes(
                                                            jobType.value,
                                                        ),
                                                )}
                                            />
                                        </div>
                                    </MDBCol>
                                </MDBRow>

                                {/* Fifth Row: Additional Notes */}
                                <MDBRow>
                                    <MDBCol size="12">
                                        <MDBTextArea
                                            wrapperClass="mb-3"
                                            id="notes"
                                            label="Job Search Specifics - specific cities, regions, shift/hours, etc."
                                            value={formData.notes}
                                            rows={4}
                                            onChange={handleFormTextareaChange}
                                        />
                                    </MDBCol>
                                </MDBRow>

                                {/* File Uploads */}
                                <em style={{ marginBottom: '12px' }}>
                                    The more documents that you can provide for
                                    us up front, the better and faster we can
                                    help you! If you're on a mobile device, no
                                    worries — feel free to submit your profile
                                    now and we can get to the documents later.
                                    <br />
                                </em>
                                <br />

                                <MDBRow>
                                    <MDBCol md="6" size="12">
                                        <span className="text-muted resume-label">
                                            Resume
                                        </span>
                                        <MDBFile
                                            multiple
                                            className="mb-3"
                                            id="resumeFiles"
                                            onChange={handleFileChange}
                                        />
                                    </MDBCol>
                                    <MDBCol md="6" size="12">
                                        <span className="text-muted resume-label">
                                            BLS Certificate
                                        </span>
                                        <MDBFile
                                            multiple
                                            className="mb-3"
                                            id="blsFiles"
                                            onChange={handleFileChange}
                                        />
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow>
                                    <MDBCol md="6" size="12">
                                        <span className="text-muted resume-label">
                                            Nursing License(s)
                                        </span>
                                        <MDBFile
                                            multiple
                                            className="mb-3"
                                            id="nursingLicenseFiles"
                                            onChange={handleFileChange}
                                        />
                                    </MDBCol>
                                    <MDBCol md="6" size="12">
                                        <span className="text-muted resume-label">
                                            References
                                        </span>
                                        <MDBFile
                                            multiple
                                            className="mb-3"
                                            id="referencesFiles"
                                            onChange={handleFileChange}
                                        />
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow>
                                    <MDBCol md="12" size="12">
                                        <span className="text-muted resume-label">
                                            Other documents (e.g., other
                                            licenses/certifications, vaccination
                                            records, etc.)
                                        </span>
                                        <MDBFile
                                            multiple
                                            className="mb-3"
                                            id="otherFiles"
                                            onChange={handleFileChange}
                                        />
                                    </MDBCol>
                                </MDBRow>

                                {formError && (
                                    <div
                                        className="submit-error-message mb-3"
                                        dangerouslySetInnerHTML={{
                                            __html: formError,
                                        }}
                                    />
                                )}

                                <MDBModalFooter>
                                    <MDBBtn
                                        color="secondary"
                                        onClick={closeModal}
                                    >
                                        Close
                                    </MDBBtn>
                                    <MDBBtn
                                        type="submit"
                                        disabled={isSubmitting}
                                        className="submit-button"
                                    >
                                        {isSubmitting ? (
                                            <MDBSpinner
                                                size="sm"
                                                role="status"
                                                tag="span"
                                            />
                                        ) : (
                                            'Submit'
                                        )}
                                    </MDBBtn>
                                </MDBModalFooter>
                            </form>
                        ) : (
                            <div className="success-message text-center">
                                <h5>Thank you for building your profile!</h5>
                                <p>
                                    A member of the team will be reaching out to
                                    you as soon as possible.
                                </p>
                                <MDBModalFooter>
                                    <MDBBtn
                                        color="primary"
                                        onClick={closeModal}
                                    >
                                        Close
                                    </MDBBtn>
                                </MDBModalFooter>
                            </div>
                        )}
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    );
};

export default BuildProfileDialog;
