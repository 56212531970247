import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit';

export interface IPrivacyPolicyPageProps {}

const PrivacyPolicyPage: React.FunctionComponent<IPrivacyPolicyPageProps> = (
    props,
) => {
    return (
        <MDBContainer fluid className="page-padding">
            <MDBRow className="text-center">
                <MDBCol size="12">
                    <h2 className="display-4">Privacy Policy</h2>
                    <hr className="my-4 centered-hr" />
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol size="12">
                    <p>
                        <em>Your Privacy Matters to Us</em>
                    </p>
                    <p>
                        We are committed to protecting your privacy and ensuring
                        that your personal information is handled in a safe and
                        responsible manner. We want to make it clear:{' '}
                        <strong>
                            we will never sell any user data that we collect
                        </strong>
                        . Your trust is important to us, and we are dedicated to
                        safeguarding your information.
                    </p>
                    <h3>Information We Collect</h3>
                    <p>
                        When you visit our website, we use Meta Pixel and
                        Microsoft Clarity to collect certain information to help
                        us track advertising from Meta and understand how
                        visitors interact with our website. The data collected
                        includes:
                    </p>
                    <ul>
                        <li>
                            Information about your device and internet
                            connection (e.g., IP address, browser type,
                            operating system)
                        </li>
                        <li>
                            Information about your visit (e.g., pages viewed,
                            time spent on the site, links clicked)
                        </li>
                    </ul>
                    <h3>How We Use Your Information</h3>
                    <p>
                        The information collected through Meta Pixel and
                        Microsoft Clarity is used solely for the purpose of:
                    </p>
                    <ul>
                        <li>
                            Tracking the effectiveness of our advertising on
                            Meta platforms
                        </li>
                        <li>
                            Understanding how visitors interact with our website
                        </li>
                        <li>
                            Improving our marketing strategies and website
                            functionality
                        </li>
                    </ul>
                    <h3>Data Sharing and Disclosure</h3>
                    <p>
                        We do not sell your personal data to any third parties.
                        The data collected by Meta Pixel may be shared with Meta
                        (Facebook) and Microsoft Clarity for the purposes of
                        analyzing and optimizing our advertising campaigns and
                        website usability. We ensure that your data is handled
                        with the utmost care and security.
                    </p>
                    <h3>Cookies, Session Storage, and Tracking Technologies</h3>
                    <p>
                        We use cookies and similar tracking technologies to
                        collect and use data as described above. Cookies are
                        small data files that are placed on your device to help
                        websites and services function and perform as expected.
                    </p>
                    <p>
                        In addition to cookies, we also use session storage to
                        enhance your user experience during your visit. Session
                        storage is used to temporarily store information such
                        as:
                    </p>
                    <ul>
                        <li>
                            <strong>Selected Filters</strong>: To maintain your
                            filter preferences during your session.
                        </li>
                        <li>
                            <strong>Scroll Position</strong>: To remember your
                            scroll position if you navigate away and return to
                            the page.
                        </li>
                    </ul>
                    <p>
                        Data stored in session storage is temporary and will be
                        cleared when you close your browser or tab. Session
                        storage is used solely for improving the functionality
                        and user experience of our website and does not track
                        your browsing activity across sessions.
                    </p>
                    <h3>Your Data Protection Rights</h3>
                    <p>You have the right to:</p>
                    <ul>
                        <li>Access the personal data we hold about you</li>
                        <li>
                            Request the correction of any incorrect or
                            incomplete data
                        </li>
                        <li>Request the deletion of your personal data</li>
                        <li>Opt out of certain data processing activities</li>
                    </ul>
                    <p>
                        If you wish to exercise any of these rights, please
                        contact us using the information provided below.
                    </p>
                    <h3>Security of Your Information</h3>
                    <p>
                        We take appropriate technical and organizational
                        measures to protect your data against unauthorized
                        access, alteration, disclosure, or destruction. However,
                        no internet-based site can be 100% secure, and we cannot
                        be held responsible for unauthorized or unintended
                        access that is beyond our control.
                    </p>
                    <h3>Changes to This Privacy Policy</h3>
                    <p>
                        We may update this privacy policy from time to time to
                        reflect changes in our practices or for other
                        operational, legal, or regulatory reasons. Any changes
                        will be posted on this page with an updated revision
                        date.
                    </p>
                    <h3>Contact Us</h3>
                    <p>
                        If you have any questions about this privacy policy or
                        our data practices, please contact us at:
                    </p>
                    <p>
                        <strong>Email</strong>:{' '}
                        <a href="mailto:info@x-staffing.com">
                            info@x-staffing.com
                        </a>
                    </p>
                    <p>
                        Thank you for trusting us with your information. We are
                        committed to protecting your privacy and ensuring that
                        your experience with us is safe and secure.
                    </p>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};

export default PrivacyPolicyPage;
