import React, { useState } from 'react';
import logo from 'assets/logo50.png';
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarItem,
    MDBNavbarToggler,
    MDBCollapse,
    MDBNavbarNav,
    MDBNavbarLink,
    MDBIcon,
} from 'mdb-react-ui-kit';
import { Link, useLocation } from 'react-router-dom';

export interface INavigationComponentProps {}

const NavigationComponent: React.FunctionComponent<
    INavigationComponentProps
> = (props) => {
    const [openNav, setOpenNav] = useState(false);
    const location = useLocation();

    const isActive = (path: string): boolean => location.pathname === path;

    return (
        <MDBNavbar expand="lg" light bgColor="light" className="fixed-top">
            <MDBContainer fluid>
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <MDBNavbarBrand>
                        <img
                            src={logo}
                            height="50"
                            className="d-inline-block align-top"
                            alt="XStaffing logo"
                        />
                    </MDBNavbarBrand>
                </Link>
                <MDBNavbarToggler
                    type="button"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={() => setOpenNav(!openNav)}
                >
                    <MDBIcon icon="bars" fas />
                </MDBNavbarToggler>

                <MDBCollapse navbar open={openNav}>
                    <MDBNavbarNav>
                        <MDBNavbarItem>
                            <Link to="/" style={{ textDecoration: 'none' }}>
                                <MDBNavbarLink
                                    active={isActive('/')}
                                    aria-current="page"
                                    onClick={() => setOpenNav(false)}
                                >
                                    Home
                                </MDBNavbarLink>
                            </Link>
                        </MDBNavbarItem>
                        <MDBNavbarItem>
                            <Link
                                to="/looking-for-work"
                                style={{ textDecoration: 'none' }}
                            >
                                <MDBNavbarLink
                                    active={isActive('/looking-for-work')}
                                    onClick={() => setOpenNav(false)}
                                >
                                    Looking for Work
                                </MDBNavbarLink>
                            </Link>
                        </MDBNavbarItem>
                        <MDBNavbarItem>
                            <Link to="/map" style={{ textDecoration: 'none' }}>
                                <MDBNavbarLink
                                    active={isActive('/map')}
                                    onClick={() => setOpenNav(false)}
                                >
                                    Interactive Map
                                </MDBNavbarLink>
                            </Link>
                        </MDBNavbarItem>
                        <MDBNavbarItem>
                            <Link
                                to="/request-staff"
                                style={{ textDecoration: 'none' }}
                            >
                                <MDBNavbarLink
                                    active={isActive('/request-staff')}
                                    onClick={() => setOpenNav(false)}
                                >
                                    Request Staff
                                </MDBNavbarLink>
                            </Link>
                        </MDBNavbarItem>
                        <MDBNavbarItem>
                            <Link
                                to="/contact"
                                style={{ textDecoration: 'none' }}
                            >
                                <MDBNavbarLink
                                    active={isActive('/contact')}
                                    onClick={() => setOpenNav(false)}
                                >
                                    Contact Us
                                </MDBNavbarLink>
                            </Link>
                        </MDBNavbarItem>
                    </MDBNavbarNav>
                </MDBCollapse>
            </MDBContainer>
        </MDBNavbar>
    );
};

export default NavigationComponent;
