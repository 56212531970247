const convertStringToArrayBuffer = (str: string): ArrayBuffer => {
    const textEncoder = new TextEncoder();
    return textEncoder.encode(str).buffer;
};

export async function convertFileToArrayBuffer(
    file: File,
): Promise<ArrayBuffer | null> {
    if (!file || !file.name) {
        throw new Error('Invalid or missing file.');
    }

    return new Promise<ArrayBuffer | null>((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            const arrayBuffer: ArrayBuffer | null | string = reader.result;

            if (arrayBuffer === null) {
                resolve(null);
                return;
            }
            if (typeof arrayBuffer === 'string') {
                resolve(convertStringToArrayBuffer(arrayBuffer));
                return;
            }
            if (!arrayBuffer) {
                reject(new Error('Failed to read file into ArrayBuffer.'));
                return;
            }

            resolve(arrayBuffer);
        };

        reader.onerror = () => {
            reject(new Error('Error reading file.'));
        };

        reader.readAsArrayBuffer(file);
    });
}
