import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import HomePage from 'pages/Home';
import NavigationComponent from 'components/Navigation';
import LookingForWorkPage from 'pages/LookingForWork';
import RequestStaffPage from 'pages/RequestStaff';
import ContactPage from 'pages/Contact';
import FooterComponent from 'components/Footer';
import JobDetailsPage from 'pages/JobDetails';
import { ScrollToTop } from 'utilities/scrollToTopHook';
import Tracking from 'components/Tracking';
import PrivacyPolicyPage from 'pages/PrivacyPolicy';
import InteractiveMapPage from 'pages/InteractiveMap';

export interface IApplicationProps {}

const Application: React.FunctionComponent<IApplicationProps> = (props) => {
    return (
        <BrowserRouter>
            <AppContent />
        </BrowserRouter>
    );
};

const AppContent: React.FunctionComponent = () => {
    const location = useLocation();

    useEffect(() => {
        // Parse the query string
        const params = new URLSearchParams(location.search);
        const twclid = params.get('twclid');
        const source = params.get('source');

        // If twclid exists, store it in local storage
        if (twclid) {
            localStorage.setItem('twclid', twclid);
        }

        // if source exists, store it in local storage
        if (source) {
            localStorage.setItem('source', source);
        }
    }, [location]);

    return (
        <>
            <ScrollToTop />
            <NavigationComponent />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route
                    path="looking-for-work"
                    element={<LookingForWorkPage />}
                />
                <Route path="map" element={<InteractiveMapPage />} />
                <Route path="request-staff" element={<RequestStaffPage />} />
                <Route path="contact" element={<ContactPage />} />
                <Route path="job/:jobId" element={<JobDetailsPage />} />
                <Route path="job">
                    <Route index element={<JobDetailsPage />} />
                    <Route path=":number" element={<JobDetailsPage />} />
                </Route>
                <Route path="privacy" element={<PrivacyPolicyPage />} />
            </Routes>
            <FooterComponent />
            <Tracking />
        </>
    );
};

export default Application;
