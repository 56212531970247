import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdb-react-ui-kit';
import backgroundImage from 'assets/homepage-background.png';
import ourMissionImage from 'assets/our-mission.png';
import 'styles/Home.css';
import BuildProfileDialog from 'components/BuildProfileDialog';

export interface IHomePageProps {}

const HomePage: React.FunctionComponent<IHomePageProps> = (props) => {
    const [showModal, setShowModal] = useState<boolean>(false);

    const navigate = useNavigate();

    const handleStartWorking = () => {
        navigate('/looking-for-work');
    };

    const handleLookingForStaff = () => {
        navigate('/request-staff');
    };

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    return (
        <MDBContainer fluid className="background-page-padding">
            {/* Build Profile Dialog */}
            <BuildProfileDialog
                showModal={showModal}
                setShowModal={setShowModal}
            />

            <MDBRow
                className="d-flex align-items-center text-center bg-image"
                style={{
                    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%), url(${backgroundImage})`,
                    height: '100vh',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                }}
            >
                <MDBCol size="12">
                    <div
                        className="overlay d-flex flex-column justify-content-center align-items-center"
                        style={{
                            height: '100%',
                        }}
                    >
                        <h1 className="display-3 text-white bold-header mb-0">
                            The Next Generation Healthcare Staffing Agency
                        </h1>
                        <p className="lead text-white mb-5">
                            Bringing Transparency to Healthcare Staffing: Your
                            Premier Healthcare Staffing Agency and Temp Agency
                            for Hospital Jobs.
                        </p>
                        <div>
                            <MDBBtn
                                color="light"
                                size="lg"
                                className="m-2"
                                onClick={handleStartWorking}
                            >
                                Start Working Today
                            </MDBBtn>
                            <MDBBtn
                                color="light"
                                size="lg"
                                className="m-2"
                                onClick={toggleModal}
                            >
                                Build Clinician Profile
                            </MDBBtn>
                            <MDBBtn
                                color="light"
                                size="lg"
                                className="m-2"
                                onClick={handleLookingForStaff}
                            >
                                Looking for Staff
                            </MDBBtn>
                        </div>
                    </div>
                </MDBCol>
            </MDBRow>

            <div className="about-us-padding">
                <MDBRow className="my-5 text-center">
                    <MDBCol size="12">
                        <h2 className="display-4">About Us</h2>
                        <hr className="my-4 centered-hr" />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="text-center mb-5">
                    <MDBCol md="6" className="mb-4">
                        <img
                            src={ourMissionImage}
                            className="img-fluid about-us-img"
                            alt="Our Values"
                        />
                    </MDBCol>
                    <MDBCol md="6" className="mb-4">
                        <h4 className="mt-4">Who We Are</h4>
                        <p className="about-us-content">
                            Welcome to XStaffing, where our mission is to
                            revolutionize healthcare staffing. Simply put, we
                            believe there's too much unnecessary bureaucracy and
                            too many companies taking a cut of the revenue that
                            clinicians deserve the most. By eliminating these
                            inefficiencies, we save money for healthcare
                            facilities and increase wages for clinicians. Our
                            focus on transparency and efficiency ensures a
                            seamless experience that benefits both healthcare
                            professionals and the facilities they serve.
                        </p>
                        <p className="about-us-content">
                            With over 5 years of experience, we understand the
                            unique challenges in healthcare staffing. Our
                            expertise allows us to ensure that facilities have
                            access to top-tier talent while professionals enjoy
                            maximum earnings and smooth placements. At
                            XStaffing, we are committed to creating value
                            through honest, efficient, and effective staffing
                            solutions, making us the trusted partner in
                            healthcare staffing.
                        </p>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="text-center mb-5">
                    <MDBCol size="12">
                        <MDBBtn
                            color="primary"
                            size="lg"
                            className="m-2"
                            onClick={handleStartWorking}
                        >
                            Start Working Today
                        </MDBBtn>
                        <MDBBtn
                            color="primary"
                            size="lg"
                            className="m-2"
                            onClick={handleLookingForStaff}
                        >
                            Looking for Staff
                        </MDBBtn>
                    </MDBCol>
                </MDBRow>
            </div>
        </MDBContainer>
    );
};

export default HomePage;
