import { useEffect, useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'react-router-dom';

const TrackingComponent = () => {
    const [cookieConsent, setCookieConsent] = useState(false);
    const [isDevelopment, setIsDevelopment] = useState(true);

    useEffect(() => {
        // CookieConsent is the cookie name that the CookieConsent component below stores and uses.
        const consent = document.cookie.includes('CookieConsent=true');
        setCookieConsent(consent);

        const develpmentEnv = process.env.NODE_ENV !== 'production';
        setIsDevelopment(develpmentEnv);
    }, []);

    useEffect(() => {
        if (cookieConsent && !isDevelopment) {
            // Inject Meta Pixel script
            const metaPixelScript = document.createElement('script');
            metaPixelScript.innerHTML = `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '782515247402390');
                fbq('track', 'PageView');
            `;
            document.head.appendChild(metaPixelScript);

            // Inject Microsoft Clarity script
            const clarityScript = document.createElement('script');
            clarityScript.innerHTML = `
                (function(c,l,a,r,i,t,y){
                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                })(window, document, "clarity", "script", "ne09wnt4l7");
            `;
            document.head.appendChild(clarityScript);
        }
    }, [cookieConsent, isDevelopment]);

    const handleAccept = () => {
        setCookieConsent(true);
    };

    const handleDecline = () => {
        setCookieConsent(false);
    };

    return (
        <>
            <CookieConsent
                enableDeclineButton
                flipButtons
                buttonText="Accept"
                buttonStyle={{
                    background: '#3b71ca',
                    color: 'white',
                    borderRadius: '4px',
                    marginRight: '0px',
                    width: '120px',
                }}
                declineButtonText="Decline"
                declineButtonStyle={{
                    background: '#8a6d3b',
                    color: 'white',
                    borderRadius: '4px',
                    marginRight: '8px',
                    width: '120px',
                }}
                onAccept={handleAccept}
                onDecline={handleDecline}
                style={{
                    background: '#FAF9F6',
                    color: 'black',
                    borderRadius: '4px',
                    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                }}
            >
                Cookies help us provide a better service for you. Your data is
                safe with us and will never be sold. &nbsp;
                <Link to="privacy">Privacy Policy.</Link>
            </CookieConsent>
        </>
    );
};

export default TrackingComponent;
