import React, { useState } from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBBtn,
    MDBSpinner,
    MDBTextArea,
    MDBIcon,
} from 'mdb-react-ui-kit';
import ApiService from 'services/ApiService';
import 'styles/Contact.css';

const apiService = new ApiService();

export interface IContactPageProps {}

interface ContactUsFormData {
    name: string;
    email: string;
    phone: string;
    message: string;
}

const ContactPage: React.FunctionComponent<IContactPageProps> = (props) => {
    const [formData, setFormData] = useState<ContactUsFormData>({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleTextAreaInputChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            setError(null);
            setSuccess(false);
            setIsSubmitting(true);
            await apiService.submitContact(formData);
            setSuccess(true);
            window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
        } catch (err) {
            setError(
                "Huh, that didn't work. Try again, and if it fails, shoot us an email at <a href='mailto:info@x-staffing.com'>info@x-staffing.com</a>.",
            );
        } finally {
            setIsSubmitting(false);
        }
    };
    return (
        <MDBContainer fluid className="page-padding">
            <MDBRow className="text-center">
                <MDBCol size="12">
                    <h2 className="display-4">Contact Us</h2>
                    <p className="sub-heading-text">
                        <em>We would love to hear from you.</em>
                    </p>
                    <hr className="my-4 centered-hr" />
                </MDBCol>
            </MDBRow>
            <MDBRow className="text-center">
                <MDBCol md="5" className="mb-4">
                    {!success ? (
                        <>
                            <p className="form-header text-left">
                                How can we help?
                            </p>
                            <form onSubmit={handleSubmit}>
                                <MDBInput
                                    wrapperClass="mb-4"
                                    id="name"
                                    label="Name *"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    required
                                />
                                <MDBInput
                                    wrapperClass="mb-4"
                                    type="email"
                                    id="email"
                                    label="Email *"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    required
                                />
                                <MDBInput
                                    wrapperClass="mb-4"
                                    type="tel"
                                    id="phone"
                                    label="Phone"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                />
                                <MDBTextArea
                                    wrapperClass="mb-4"
                                    id="message"
                                    label="Message"
                                    value={formData.message}
                                    rows={4}
                                    onChange={handleTextAreaInputChange}
                                />
                                <MDBBtn
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="submit-button"
                                >
                                    {isSubmitting ? (
                                        <MDBSpinner
                                            size="sm"
                                            role="status"
                                            tag="span"
                                        />
                                    ) : (
                                        'Submit'
                                    )}
                                </MDBBtn>
                            </form>
                        </>
                    ) : (
                        <div className="success-message">
                            <h3>Thank you for your submission!</h3>
                            <p>
                                A member of the team will be reaching out to you
                                as soon as possible.
                            </p>
                        </div>
                    )}
                    {error && (
                        <div
                            className="submit-error-message"
                            dangerouslySetInnerHTML={{ __html: error }}
                        />
                    )}
                </MDBCol>
                <MDBCol md="2" />
                <MDBCol md="5" className="text-left">
                    <h5>XStaffing</h5>
                    <br />
                    <p className="contact-info-text">
                        <MDBIcon icon="home" className="me-2" />
                        &nbsp;&nbsp;Lincoln, Nebraska, United States
                    </p>
                    <br />
                    <p className="contact-info-text">
                        <MDBIcon icon="user" className="me-3" />
                        &nbsp;Davis Peterson - Owner/Partner
                    </p>
                    <p className="contact-info-text text-hover-black">
                        <MDBIcon icon="envelope" className="me-3" />
                        <a
                            href="mailto:davis.peterson@x-staffing.com"
                            className="text-reset"
                        >
                            davis.peterson@x-staffing.com
                        </a>
                    </p>
                    <p className="contact-info-text text-hover-black">
                        <MDBIcon icon="phone" className="me-3" />
                        <a href="tel:+14022020672" className="text-reset">
                            402.202.0672
                        </a>
                    </p>
                    <br />
                    <p className="contact-info-text">
                        <MDBIcon icon="user" className="me-3" />
                        &nbsp;Gunnar Peterson - Owner/Partner
                    </p>
                    <p className="contact-info-text text-hover-black">
                        <MDBIcon icon="envelope" className="me-3" />
                        <a
                            href="mailto:gunnar.peterson@x-staffing.com"
                            className="text-reset"
                        >
                            gunnar.peterson@x-staffing.com
                        </a>
                    </p>
                    <p className="contact-info-text text-hover-black">
                        <MDBIcon icon="phone" className="me-3" />
                        <a href="tel:+14023107774" className="text-reset">
                            402.310.7774
                        </a>
                    </p>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};

export default ContactPage;
