import React, { useState, useEffect } from 'react';
import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBBtn,
    MDBInput,
    MDBFile,
    MDBSpinner,
    MDBRow,
    MDBCol,
} from 'mdb-react-ui-kit';
import ApiService from 'services/ApiService';
import {
    ApplyRequestModel,
    UploadDocumentsResponseModel,
} from 'services/ApiContracts';
import { BlockBlobClient } from '@azure/storage-blob';
import { convertFileToArrayBuffer } from 'utilities/uploadHelpers';
import logo from 'assets/logo50.png';

interface ApplyForJobDialogProps {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
    jobId: number;
}

const apiService = new ApiService();

const ApplyForJobDialog: React.FC<ApplyForJobDialogProps> = ({
    showModal,
    setShowModal,
    jobId,
}) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [formError, setFormError] = useState<string | null>(null);
    const [formSuccess, setFormSuccess] = useState<boolean>(false);

    interface ApplyFormData {
        name: string;
        email: string;
        phone: string;
        specialty?: string;
    }

    const [formData, setFormData] = useState<ApplyFormData>({
        name: '',
        email: '',
        phone: '',
        specialty: '',
    });

    const [resumeFiles, setResumeFiles] = useState<FileList | null>(null);
    const [blsFiles, setBlsFiles] = useState<FileList | null>(null);
    const [nursingLicenseFiles, setNursingLicenseFiles] =
        useState<FileList | null>(null);
    const [referencesFiles, setReferencesFiles] = useState<FileList | null>(
        null,
    );
    const [otherFiles, setOtherFiles] = useState<FileList | null>(null);

    // Reset form when modal opens
    useEffect(() => {
        if (showModal) {
            setFormData({
                name: '',
                email: '',
                phone: '',
                specialty: '',
            });
            setResumeFiles(null);
            setBlsFiles(null);
            setNursingLicenseFiles(null);
            setReferencesFiles(null);
            setOtherFiles(null);
            setFormError(null);
            setFormSuccess(false);
        }
    }, [showModal]);

    const closeModal = () => {
        setShowModal(false);

        setFormData({
            name: '',
            email: '',
            phone: '',
            specialty: '',
        });
    };

    const handleFormInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, files } = e.target;
        switch (id) {
            case 'resumeFiles':
                setResumeFiles(files);
                break;
            case 'blsFiles':
                setBlsFiles(files);
                break;
            case 'nursingLicenseFiles':
                setNursingLicenseFiles(files);
                break;
            case 'referencesFiles':
                setReferencesFiles(files);
                break;
            case 'otherFiles':
                setOtherFiles(files);
                break;
            default:
                break;
        }
    };

    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFormError(null);
        setFormSuccess(false);
        setIsSubmitting(true);

        try {
            let uploadId: string | undefined;
            const allFiles: File[] = [];

            if (resumeFiles) {
                allFiles.push(...Array.from(resumeFiles));
            }
            if (blsFiles) {
                allFiles.push(...Array.from(blsFiles));
            }
            if (nursingLicenseFiles) {
                allFiles.push(...Array.from(nursingLicenseFiles));
            }
            if (referencesFiles) {
                allFiles.push(...Array.from(referencesFiles));
            }
            if (otherFiles) {
                allFiles.push(...Array.from(otherFiles));
            }

            // Check required fields
            if (!formData.name || !formData.email || !formData.phone) {
                setFormError(
                    'Please fill out all required fields marked with *.',
                );
                setIsSubmitting(false);
                return;
            }

            // Check required files
            if (!resumeFiles || resumeFiles.length === 0) {
                setFormError(
                    'Please upload all required documents marked with *.',
                );
                setIsSubmitting(false);
                return;
            }

            if (allFiles.length > 0) {
                // Ensure unique filenames
                const fileNameCounts: { [key: string]: number } = {};
                const uniqueFiles: { file: File; uniqueName: string }[] = [];

                for (const file of allFiles) {
                    let fileName = file.name;
                    if (fileNameCounts[fileName]) {
                        const count = ++fileNameCounts[fileName];
                        const dotIndex = fileName.lastIndexOf('.');
                        const name =
                            dotIndex !== -1
                                ? fileName.substring(0, dotIndex)
                                : fileName;
                        const extension =
                            dotIndex !== -1 ? fileName.substring(dotIndex) : '';
                        fileName = `${name}(${count})${extension}`;
                    } else {
                        fileNameCounts[fileName] = 1;
                    }
                    uniqueFiles.push({ file, uniqueName: fileName });
                }

                const fileNames = uniqueFiles.map((item) => item.uniqueName);
                const uploadRequest = {
                    uploaderName: formData.name,
                    fileNames,
                    submissionType: 'Apply',
                };

                const uploadResponse: UploadDocumentsResponseModel =
                    await apiService.uploadDocuments(uploadRequest);

                const promises = uniqueFiles.map(async (item) => {
                    try {
                        const sasUri = uploadResponse.sasUris[item.uniqueName];
                        if (!sasUri) {
                            return;
                        }

                        const fileData = await convertFileToArrayBuffer(
                            item.file,
                        );
                        if (!fileData) {
                            return;
                        }

                        const blockBlobClient = new BlockBlobClient(sasUri);
                        await blockBlobClient.uploadData(fileData);
                    } catch (err) {
                        console.error(
                            `Error uploading file ${item.uniqueName}:`,
                            err,
                        );
                    }
                });

                await Promise.all(promises);
                uploadId = uploadResponse.uploadId;
            }

            const applyRequest: ApplyRequestModel = {
                ...formData,
                jobId: jobId,
                uploadId,
            };

            await apiService.applyForJob(applyRequest);
            setFormSuccess(true);

            // Meta pixel event tracking.
            if (window.fbq) {
                window.fbq('track', 'SubmitApplication', {
                    content_name: 'job_app_submitted',
                    job_id: jobId,
                    documents_included: allFiles.length > 0,
                    resume_included: resumeFiles && resumeFiles.length > 0,
                    bls_included: blsFiles && blsFiles.length > 0,
                    nursing_license_included:
                        nursingLicenseFiles && nursingLicenseFiles.length > 0,
                    references_included:
                        referencesFiles && referencesFiles.length > 0,
                    other_documents_included:
                        otherFiles && otherFiles.length > 0,
                });
            }
        } catch (err) {
            setFormError(
                "Huh, that didn't work. Try again, and if it fails, shoot us an email at <a href='mailto:info@x-staffing.com'>info@x-staffing.com</a>.",
            );
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <MDBModal open={showModal} setOpen={setShowModal} tabIndex={-1}>
            <MDBModalDialog size="lg" scrollable>
                <MDBModalContent>
                    <MDBModalHeader className="modal-header d-flex align-items-center">
                        <img src={logo} alt="Logo" className="me-3" />
                        <div className="flex-grow-1">
                            <MDBModalTitle>Apply for Job</MDBModalTitle>
                            <em>
                                Apply here, and we'll contact you to get the
                                rest of your information!
                            </em>
                        </div>
                        <MDBBtn
                            className="btn-close btn-close-white"
                            color="none"
                            onClick={closeModal}
                        ></MDBBtn>
                    </MDBModalHeader>

                    <MDBModalBody className="modal-body">
                        {!formSuccess ? (
                            <form onSubmit={handleFormSubmit}>
                                {/* First Row: Name and Email */}
                                <MDBRow>
                                    <MDBCol md="6" size="12">
                                        <MDBInput
                                            wrapperClass="mb-3"
                                            id="name"
                                            label="Name *"
                                            value={formData.name}
                                            onChange={handleFormInputChange}
                                            required
                                        />
                                    </MDBCol>
                                    <MDBCol md="6" size="12">
                                        <MDBInput
                                            wrapperClass="mb-3"
                                            type="email"
                                            id="email"
                                            label="Email *"
                                            value={formData.email}
                                            onChange={handleFormInputChange}
                                            required
                                        />
                                    </MDBCol>
                                </MDBRow>

                                {/* Second Row: Phone and Specialty */}
                                <MDBRow>
                                    <MDBCol md="6" size="12">
                                        <MDBInput
                                            wrapperClass="mb-3"
                                            type="tel"
                                            id="phone"
                                            label="Phone *"
                                            value={formData.phone}
                                            onChange={handleFormInputChange}
                                            required
                                        />
                                    </MDBCol>
                                    <MDBCol md="6" size="12">
                                        <MDBInput
                                            wrapperClass="mb-3"
                                            id="specialty"
                                            label="Specialty"
                                            value={formData.specialty}
                                            onChange={handleFormInputChange}
                                        />
                                    </MDBCol>
                                </MDBRow>

                                {/* Third Row: Resume and BLS Certificate */}
                                <MDBRow>
                                    <MDBCol md="6" size="12">
                                        <span className="text-muted resume-label">
                                            Resume *
                                        </span>
                                        <MDBFile
                                            multiple
                                            className="mb-3"
                                            id="resumeFiles"
                                            onChange={handleFileChange}
                                            required
                                        />
                                    </MDBCol>
                                    <MDBCol md="6" size="12">
                                        <span className="text-muted resume-label">
                                            BLS Certificate *
                                        </span>
                                        <MDBFile
                                            multiple
                                            className="mb-3"
                                            id="blsFiles"
                                            onChange={handleFileChange}
                                        />
                                    </MDBCol>
                                </MDBRow>

                                {/* Fourth Row: Nursing Licenses and References */}
                                <MDBRow>
                                    <MDBCol md="6" size="12">
                                        <span className="text-muted resume-label">
                                            Nursing License(s) *
                                        </span>
                                        <MDBFile
                                            multiple
                                            className="mb-3"
                                            id="nursingLicenseFiles"
                                            onChange={handleFileChange}
                                        />
                                    </MDBCol>
                                    <MDBCol md="6" size="12">
                                        <span className="text-muted resume-label">
                                            References
                                        </span>
                                        <MDBFile
                                            multiple
                                            className="mb-3"
                                            id="referencesFiles"
                                            onChange={handleFileChange}
                                        />
                                    </MDBCol>
                                </MDBRow>

                                {/* Fifth Row: Other Documents */}
                                <MDBRow>
                                    <MDBCol md="12" size="12">
                                        <span className="text-muted resume-label">
                                            Other documents (e.g., other
                                            licenses/certifications, vaccination
                                            records, etc.)
                                        </span>
                                        <MDBFile
                                            multiple
                                            className="mb-3"
                                            id="otherFiles"
                                            onChange={handleFileChange}
                                        />
                                    </MDBCol>
                                </MDBRow>

                                {formError && (
                                    <div
                                        className="submit-error-message mb-3"
                                        dangerouslySetInnerHTML={{
                                            __html: formError,
                                        }}
                                    />
                                )}

                                <MDBModalFooter>
                                    <MDBBtn
                                        color="secondary"
                                        onClick={closeModal}
                                    >
                                        Close
                                    </MDBBtn>
                                    <MDBBtn
                                        type="submit"
                                        disabled={isSubmitting}
                                        className="submit-button"
                                    >
                                        {isSubmitting ? (
                                            <MDBSpinner
                                                size="sm"
                                                role="status"
                                                tag="span"
                                            />
                                        ) : (
                                            'Apply'
                                        )}
                                    </MDBBtn>
                                </MDBModalFooter>
                            </form>
                        ) : (
                            <div className="success-message text-center">
                                <h5>Thank you for your application!</h5>
                                <p>
                                    A member of the team will be reaching out to
                                    you as soon as possible to gather the rest
                                    of your info.
                                </p>
                                <MDBModalFooter>
                                    <MDBBtn
                                        color="primary"
                                        onClick={closeModal}
                                    >
                                        Close
                                    </MDBBtn>
                                </MDBModalFooter>
                            </div>
                        )}
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    );
};

export default ApplyForJobDialog;
