import React from 'react';
import { Link } from 'react-router-dom';
import {
    MDBFooter,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBBtn,
} from 'mdb-react-ui-kit';

export interface IFooterComponentProps {}

const FooterComponent: React.FunctionComponent<IFooterComponentProps> = (
    props,
) => {
    return (
        <MDBFooter bgColor="light" className="text-center text-muted">
            <section style={{ padding: 8 }} className="border-bottom">
                <MDBBtn
                    floating
                    className="m-1"
                    style={{ backgroundColor: '#3b5998' }}
                    href="https://www.facebook.com/profile.php?id=61563182735455"
                    target="_blank"
                    role="button"
                >
                    <MDBIcon fab icon="facebook-f" />
                </MDBBtn>

                <MDBBtn
                    floating
                    className="m-1"
                    style={{ backgroundColor: '#000000' }}
                    href="https://www.x.com/x_staffing"
                    target="_blank"
                    role="button"
                >
                    <MDBIcon fab icon="x-twitter" />
                </MDBBtn>

                <MDBBtn
                    floating
                    className="m-1"
                    style={{ backgroundColor: '#ac2bac' }}
                    href="https://www.instagram.com/xstaffing_healthcare/"
                    target="_blank"
                    role="button"
                >
                    <MDBIcon fab icon="instagram" />
                </MDBBtn>

                <MDBBtn
                    floating
                    className="m-1"
                    style={{ backgroundColor: '#0082ca' }}
                    href="https://www.linkedin.com/company/xstaffing/about/"
                    target="_blank"
                    role="button"
                >
                    <MDBIcon fab icon="linkedin-in" />
                </MDBBtn>
            </section>

            <section className="">
                <MDBContainer className="text-center text-md-start mt-4">
                    <MDBRow className="mt-4">
                        <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
                            <h6 className="text-uppercase fw-bold mb-4">
                                Useful links
                            </h6>
                            <p className="text-hover-black">
                                <Link to="/" className="text-reset">
                                    Home
                                </Link>
                            </p>
                            <p className="text-hover-black">
                                <Link
                                    to="looking-for-work"
                                    className="text-reset"
                                >
                                    Looking for Work
                                </Link>
                            </p>
                            <p className="text-hover-black">
                                <Link to="map" className="text-reset">
                                    Interactive Map
                                </Link>
                            </p>
                            <p className="text-hover-black">
                                <Link to="request-staff" className="text-reset">
                                    Request Staff
                                </Link>
                            </p>
                            <p className="text-hover-black">
                                <Link to="contact" className="text-reset">
                                    Contact Us
                                </Link>
                            </p>
                            <p className="text-hover-black">
                                <Link to="privacy" className="text-reset">
                                    Privacy Policy
                                </Link>
                            </p>
                        </MDBCol>

                        <MDBCol
                            md="4"
                            lg="3"
                            xl="3"
                            className="mx-auto mb-md-0 mb-4"
                        >
                            <h6 className="text-uppercase fw-bold mb-4">
                                Contact
                            </h6>
                            <p>
                                <MDBIcon icon="home" className="me-2" />
                                &nbsp;&nbsp;Lincoln, NE
                            </p>
                            <p>
                                <MDBIcon icon="envelope" className="me-3" />
                                <a href="mailto:info@x-staffing.com">
                                    info@x-staffing.com
                                </a>
                            </p>
                            <p className="text-hover-black">
                                <MDBIcon icon="phone" className="me-3" />
                                <a
                                    href="tel:+14022020672"
                                    className="text-reset"
                                >
                                    402.202.0672
                                </a>
                            </p>
                            <p className="text-hover-black">
                                <MDBIcon icon="phone" className="me-3" />
                                <a
                                    href="tel:+14023107774"
                                    className="text-reset"
                                >
                                    402.310.7774
                                </a>
                            </p>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </section>

            <div
                className="text-center p-4"
                style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}
            >
                Copyright © 2024 XStaffing - All Rights Reserved.
            </div>
        </MDBFooter>
    );
};

export default FooterComponent;
