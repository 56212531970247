import React, { useEffect, useState, useCallback } from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBSpinner,
    MDBBtn,
    MDBInput,
    MDBIcon,
} from 'mdb-react-ui-kit';
import ApiService from 'services/ApiService';
import { Link, useNavigate } from 'react-router-dom';
import {
    MapContainer,
    TileLayer,
    Popup,
    CircleMarker,
    useMap,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import {
    JobLocationModel,
    JobFiltersResponseModel,
    JobLocationsRequestModel,
} from 'services/ApiContracts';
import Select from 'react-select';
import { selectStyles } from 'utilities/reactSelectStyles';
import queryString from 'query-string';
import BuildProfileDialog from 'components/BuildProfileDialog';
import 'styles/InteractiveMap.css';

const apiService = new ApiService();

export interface IInteractiveMapPageProps {}

interface GroupedLocation {
    latitude: number;
    longitude: number;
    jobs: { jobId: number; jobDescription: string }[];
}

const InteractiveMapPage: React.FunctionComponent<IInteractiveMapPageProps> = (
    props,
) => {
    const [jobLocations, setJobLocations] = useState<JobLocationModel[]>([]);
    const [groupedJobLocations, setGroupedJobLocations] = useState<
        GroupedLocation[]
    >([]);
    const [bounds, setBounds] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [noJobsFound, setNoJobsFound] = useState<boolean>(false);

    // Filter state variables
    const [jobTypes, setJobTypes] = useState<string[]>([]);
    const [states, setStates] = useState<string[]>([]);
    const [professions, setProfessions] = useState<string[]>([]);
    const [specialties, setSpecialties] = useState<string[]>([]);
    const [categories, setCategories] = useState<string[]>([]);
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [filterOptions, setFilterOptions] = useState<JobFiltersResponseModel>(
        {
            categories: [],
            professions: [],
            specialties: [],
            states: [],
            jobTypes: [],
        },
    );
    const navigate = useNavigate();

    // Applied Filters
    const [appliedFilters, setAppliedFilters] = useState<{
        jobTypes: string[];
        states: string[];
        professions: string[];
        specialties: string[];
        categories: string[];
        searchFilter: string;
    }>({
        jobTypes: [],
        states: [],
        professions: [],
        specialties: [],
        categories: [],
        searchFilter: '',
    });

    // Modal for Build Profile
    const [showModal, setShowModal] = useState<boolean>(false);

    // Fetch job filters when the component mounts
    useEffect(() => {
        const fetchJobFilters = async () => {
            try {
                const filters = await apiService.jobFilters();
                setFilterOptions(filters);
            } catch (error) {
                console.error('Failed to fetch job filters:', error);
            }
        };

        fetchJobFilters();

        const queryParams = new URLSearchParams(window.location.search);

        // Determine if any query parameters are present
        const hasQueryParams =
            queryParams.has('jobTypes') ||
            queryParams.has('states') ||
            queryParams.has('professions') ||
            queryParams.has('specialties') ||
            queryParams.has('categories') ||
            queryParams.has('searchFilter');

        const initialJobTypes =
            hasQueryParams && queryParams.get('jobTypes')
                ? queryParams.get('jobTypes')!.split(',')
                : !hasQueryParams && sessionStorage.getItem('map-jobTypes')
                ? JSON.parse(sessionStorage.getItem('map-jobTypes')!)
                : [];

        const initialStates =
            hasQueryParams && queryParams.get('states')
                ? queryParams.get('states')!.split(',')
                : !hasQueryParams && sessionStorage.getItem('map-states')
                ? JSON.parse(sessionStorage.getItem('map-states')!)
                : [];

        const initialProfessions =
            hasQueryParams && queryParams.get('professions')
                ? queryParams.get('professions')!.split(',')
                : !hasQueryParams && sessionStorage.getItem('map-professions')
                ? JSON.parse(sessionStorage.getItem('map-professions')!)
                : [];

        const initialSpecialties =
            hasQueryParams && queryParams.get('specialties')
                ? queryParams.get('specialties')!.split(',')
                : !hasQueryParams && sessionStorage.getItem('map-specialties')
                ? JSON.parse(sessionStorage.getItem('map-specialties')!)
                : [];

        const initialCategories =
            hasQueryParams && queryParams.get('categories')
                ? queryParams.get('categories')!.split(',')
                : !hasQueryParams && sessionStorage.getItem('map-categories')
                ? JSON.parse(sessionStorage.getItem('map-categories')!)
                : [];

        const initialSearchFilter =
            hasQueryParams && queryParams.get('searchFilter')
                ? queryParams.get('searchFilter')!
                : !hasQueryParams && sessionStorage.getItem('map-searchFilter')
                ? sessionStorage.getItem('map-searchFilter')!
                : '';

        setJobTypes(initialJobTypes);
        setStates(initialStates);
        setProfessions(initialProfessions);
        setSpecialties(initialSpecialties);
        setCategories(initialCategories);
        setSearchFilter(initialSearchFilter);

        // Fetch job locations with the initial filters
        const filters: JobLocationsRequestModel = {
            jobTypes: initialJobTypes,
            states: initialStates,
            professions: initialProfessions,
            specialties: initialSpecialties,
            categories: initialCategories,
            searchFilter: initialSearchFilter,
        };

        updateURLWithFilters(filters);
        fetchJobLocations(filters);

        // Read 'buildProfile' parameter from URL
        const buildProfile = queryParams.get('buildProfile');

        if (buildProfile === 'true') {
            setShowModal(false); // Ensure showModal is false
            toggleModal(); // Open the dialog
        }
    }, []);

    const updateURLWithFilters = (filters: JobLocationsRequestModel) => {
        const filteredQuery = Object.fromEntries(
            Object.entries({
                jobTypes:
                    filters.jobTypes.length > 0
                        ? filters.jobTypes.join(',')
                        : undefined,
                states:
                    filters.states.length > 0
                        ? filters.states.join(',')
                        : undefined,
                professions:
                    filters.professions.length > 0
                        ? filters.professions.join(',')
                        : undefined,
                specialties:
                    filters.specialties.length > 0
                        ? filters.specialties.join(',')
                        : undefined,
                categories:
                    filters.categories.length > 0
                        ? filters.categories.join(',')
                        : undefined,
                searchFilter: filters.searchFilter || undefined,
            }).filter(([, value]) => value !== undefined),
        );
        const query = queryString.stringify(filteredQuery);
        navigate(`?${query}`);
    };

    const fetchJobLocations = async (filters: JobLocationsRequestModel) => {
        setLoading(true);
        setNoJobsFound(false);

        try {
            const response = await apiService.jobLocations(filters);
            setJobLocations(response.locations);

            // Group job locations by latitude and longitude
            const groupedLocationsMap = new Map<string, GroupedLocation>();

            response.locations.forEach((location) => {
                const key = `${location.latitude},${location.longitude}`;
                if (groupedLocationsMap.has(key)) {
                    groupedLocationsMap.get(key)!.jobs.push({
                        jobId: location.jobId,
                        jobDescription: location.jobDescription,
                    });
                } else {
                    groupedLocationsMap.set(key, {
                        latitude: location.latitude,
                        longitude: location.longitude,
                        jobs: [
                            {
                                jobId: location.jobId,
                                jobDescription: location.jobDescription,
                            },
                        ],
                    });
                }
            });

            const groupedLocations = Array.from(groupedLocationsMap.values());
            setGroupedJobLocations(groupedLocations);

            // Adjust map bounds based on the locations
            if (groupedLocations.length > 0) {
                const latitudes = groupedLocations.map((loc) => loc.latitude);
                const longitudes = groupedLocations.map((loc) => loc.longitude);
                const minLatitude = Math.min(...latitudes);
                const maxLatitude = Math.max(...latitudes);
                const minLongitude = Math.min(...longitudes);
                const maxLongitude = Math.max(...longitudes);

                const corner1 = [minLatitude, minLongitude];
                const corner2 = [maxLatitude, maxLongitude];

                setBounds([corner1, corner2]);
            } else {
                // If no locations, reset bounds to default view
                setNoJobsFound(true);
                setBounds(null);
            }

            // Set applied filters
            setAppliedFilters({
                jobTypes: filters.jobTypes,
                states: filters.states,
                professions: filters.professions,
                specialties: filters.specialties,
                categories: filters.categories,
                searchFilter: filters.searchFilter,
            });
        } catch (error) {
            console.error('Error fetching job locations:', error);
            setNoJobsFound(true);
        } finally {
            setLoading(false); // Set loading to false after fetching is complete
        }
    };

    // Handle filter changes
    const handleJobTypeChange = (selectedOptions: any) => {
        setJobTypes(selectedOptions.map((option: any) => option.value));
    };

    const handleStateChange = (selectedOptions: any) => {
        setStates(selectedOptions.map((option: any) => option.value));
    };

    const handleProfessionChange = (selectedOptions: any) => {
        setProfessions(selectedOptions.map((option: any) => option.value));
    };

    const handleSpecialtyChange = (selectedOptions: any) => {
        setSpecialties(selectedOptions.map((option: any) => option.value));
    };

    const handleCategoryChange = (selectedOptions: any) => {
        setCategories(selectedOptions.map((option: any) => option.value));
    };

    const handleSearchFilterChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setSearchFilter(event.target.value);
    };

    const handleSearch = () => {
        sessionStorage.setItem('map-jobTypes', JSON.stringify(jobTypes));
        sessionStorage.setItem('map-states', JSON.stringify(states));
        sessionStorage.setItem('map-professions', JSON.stringify(professions));
        sessionStorage.setItem('map-specialties', JSON.stringify(specialties));
        sessionStorage.setItem('map-categories', JSON.stringify(categories));
        sessionStorage.setItem('map-searchFilter', searchFilter);

        const filters: JobLocationsRequestModel = {
            jobTypes: jobTypes,
            states: states,
            professions: professions,
            specialties: specialties,
            categories: categories,
            searchFilter: searchFilter,
        };

        // Facebook tracking.
        if (window.fbq) {
            const contents: { type: string; value: string }[] = [];

            if (jobTypes.length > 0) {
                contents.push({
                    type: 'jobTypes',
                    value: jobTypes.join(','),
                });
            }

            if (states.length > 0) {
                contents.push({
                    type: 'states',
                    value: states.join(','),
                });
            }

            if (professions.length > 0) {
                contents.push({
                    type: 'professions',
                    value: professions.join(','),
                });
            }

            if (specialties.length > 0) {
                contents.push({
                    type: 'specialties',
                    value: specialties.join(','),
                });
            }

            if (categories.length > 0) {
                contents.push({
                    type: 'categories',
                    value: categories.join(','),
                });
            }

            if (searchFilter) {
                contents.push({
                    type: 'searchFilter',
                    value: searchFilter,
                });
            }

            window.fbq('track', 'Search', {
                search_string: searchFilter,
                contents: contents,
            });
        }

        updateURLWithFilters(filters);
        fetchJobLocations(filters);
    };

    const haveFiltersChanged = useCallback(() => {
        return (
            JSON.stringify(appliedFilters.jobTypes) !==
                JSON.stringify(jobTypes) ||
            JSON.stringify(appliedFilters.states) !== JSON.stringify(states) ||
            JSON.stringify(appliedFilters.professions) !==
                JSON.stringify(professions) ||
            JSON.stringify(appliedFilters.specialties) !==
                JSON.stringify(specialties) ||
            JSON.stringify(appliedFilters.categories) !==
                JSON.stringify(categories) ||
            appliedFilters.searchFilter !== searchFilter
        );
    }, [
        appliedFilters,
        jobTypes,
        states,
        professions,
        specialties,
        categories,
        searchFilter,
    ]);

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    // Custom component to adjust the map bounds with max zoom level
    function MapBoundsUpdater({ bounds }: { bounds: any }) {
        const map = useMap();

        useEffect(() => {
            if (bounds) {
                map.fitBounds(bounds, { padding: [50, 50], maxZoom: 6 });
            } else {
                // Reset to default view if no bounds
                map.setView([37.0902, -95.7129], 4);
            }
        }, [bounds, map]);

        return null;
    }

    return (
        <MDBContainer fluid className="page-padding">
            {/* Build Profile Dialog */}
            <BuildProfileDialog
                showModal={showModal}
                setShowModal={setShowModal}
            />

            {/* Build Profile Button */}
            <MDBRow className="mb-4">
                <MDBCol className="text-left">
                    <MDBBtn color="secondary" onClick={toggleModal}>
                        <MDBIcon fas icon="user-plus" className="me-2" />
                        Build Clinician Profile
                    </MDBBtn>
                </MDBCol>
            </MDBRow>

            {/* Search Jobs Row */}
            <MDBRow className="text-center">
                <MDBCol>
                    <h2 className="display-4">Interactive Map</h2>
                    <p className="sub-heading-text">
                        <em>
                            Explore our interactive map showcasing all of our
                            current openings. Click on any location to learn
                            more, or visit our{' '}
                            <Link to="/looking-for-work">
                                looking for work page
                            </Link>{' '}
                            to see detailed job information.
                        </em>
                    </p>
                    <hr className="my-4 centered-hr" />
                </MDBCol>
            </MDBRow>

            {/* Filter Section */}
            <div className="filter-container">
                <MDBRow className="text-center mb-4">
                    <strong>
                        <em>
                            Looking for a specific role? Select your options and
                            press Apply Filters.
                        </em>
                    </strong>
                </MDBRow>

                {/* Filtering Rows */}
                <MDBRow>
                    <MDBCol md="4" size="12" className="mb-3">
                        <Select
                            isMulti
                            options={filterOptions.categories}
                            placeholder="Job Categories"
                            onChange={handleCategoryChange}
                            value={filterOptions.categories.filter((category) =>
                                categories.includes(category.value),
                            )}
                            styles={selectStyles}
                        />
                    </MDBCol>
                    <MDBCol md="4" size="12" className="mb-3">
                        <Select
                            isMulti
                            options={filterOptions.professions}
                            placeholder="Professions"
                            onChange={handleProfessionChange}
                            value={filterOptions.professions.filter(
                                (profession) =>
                                    professions.includes(profession.value),
                            )}
                            styles={selectStyles}
                        />
                    </MDBCol>
                    <MDBCol md="4" size="12" className="mb-3">
                        <Select
                            isMulti
                            options={filterOptions.jobTypes}
                            placeholder="Job Types"
                            onChange={handleJobTypeChange}
                            value={filterOptions.jobTypes.filter((jobType) =>
                                jobTypes.includes(jobType.value),
                            )}
                            styles={selectStyles}
                        />
                    </MDBCol>
                </MDBRow>

                <MDBRow>
                    <MDBCol md="4" size="12" className="mb-3">
                        <Select
                            isMulti
                            options={filterOptions.states}
                            placeholder="States"
                            onChange={handleStateChange}
                            value={filterOptions.states.filter((state) =>
                                states.includes(state.value),
                            )}
                            styles={selectStyles}
                        />
                    </MDBCol>
                    <MDBCol md="4" size="12" className="mb-3">
                        <Select
                            isMulti
                            options={filterOptions.specialties}
                            placeholder="Specialties"
                            onChange={handleSpecialtyChange}
                            value={filterOptions.specialties.filter(
                                (specialty) =>
                                    specialties.includes(specialty.value),
                            )}
                            styles={selectStyles}
                        />
                    </MDBCol>
                    <MDBCol md="4" size="12" className="mb-3">
                        <MDBInput
                            label="Keyword Search"
                            value={searchFilter}
                            onChange={handleSearchFilterChange}
                        />
                    </MDBCol>
                </MDBRow>

                {/* Apply Button Row */}
                <MDBRow className="text-center">
                    <MDBCol>
                        <MDBBtn
                            onClick={handleSearch}
                            color="primary"
                            className="apply-button"
                            disabled={!haveFiltersChanged()}
                        >
                            Apply Filters
                        </MDBBtn>
                    </MDBCol>
                </MDBRow>
            </div>

            {/* Map Section */}
            <MDBRow className="my-4">
                <MDBCol size="12">
                    {loading ? (
                        // Show the loading spinner while data is loading
                        <MDBContainer
                            className="text-center"
                            style={{ height: '500px' }}
                        >
                            <MDBSpinner size="lg" color="primary" />
                        </MDBContainer>
                    ) : noJobsFound ? (
                        <MDBCol className="text-center">
                            <p className="no-jobs-found">
                                No jobs found. Try adjusting the filters above
                                to explore more opportunities, or click 'Build
                                Clinician Profile' — we'll help you find the
                                perfect match for your skills and preferences!
                            </p>
                        </MDBCol>
                    ) : (
                        // Show the map once data has loaded
                        <MapContainer
                            style={{ height: '500px', width: '100%' }}
                        >
                            <TileLayer
                                attribution="&copy; OpenStreetMap contributors"
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <MapBoundsUpdater bounds={bounds} />
                            {groupedJobLocations.map((location, index) => (
                                <CircleMarker
                                    key={index}
                                    center={[
                                        location.latitude,
                                        location.longitude,
                                    ]}
                                    radius={6}
                                    fillColor="grey"
                                    color="blue"
                                    weight={1}
                                    fillOpacity={0.8}
                                >
                                    <Popup>
                                        <div
                                            style={{
                                                maxHeight: '200px',
                                                overflowY: 'auto',
                                            }}
                                        >
                                            {location.jobs.length > 1 ? (
                                                <div>
                                                    <h4>
                                                        {location.jobs.length}{' '}
                                                        Jobs at this location
                                                    </h4>
                                                    <ul>
                                                        {location.jobs.map(
                                                            (job) => (
                                                                <li
                                                                    key={
                                                                        job.jobId
                                                                    }
                                                                >
                                                                    <Link
                                                                        to={`/job/${job.jobId}`}
                                                                    >
                                                                        {
                                                                            job.jobDescription
                                                                        }
                                                                    </Link>
                                                                </li>
                                                            ),
                                                        )}
                                                    </ul>
                                                </div>
                                            ) : (
                                                <div>
                                                    <Link
                                                        to={`/job/${location.jobs[0].jobId}`}
                                                    >
                                                        {
                                                            location.jobs[0]
                                                                .jobDescription
                                                        }
                                                    </Link>
                                                </div>
                                            )}
                                        </div>
                                    </Popup>
                                </CircleMarker>
                            ))}
                        </MapContainer>
                    )}
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};

export default InteractiveMapPage;
