import React, { useState } from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBBtn,
    MDBSpinner,
} from 'mdb-react-ui-kit';
import Select from 'react-select';
import ourMissionImage from 'assets/our-mission.png';
import 'styles/RequestStaff.css';
import ApiService from 'services/ApiService';
import { selectStyles } from 'utilities/reactSelectStyles';

const apiService = new ApiService();

export interface IRequestStaffProps {}

interface RequestStaffFormData {
    name: string;
    email: string;
    phone: string;
    jobTitle: string;
    organization: string;
    facilityType: string;
    location: string;
    staffingType: string[];
}

const facilityTypeOptions = [
    { value: 'acute', label: 'Acute Care' },
    { value: 'hospice', label: 'Home Health/Hospice' },
    { value: 'longtermcare', label: 'Long Term Care' },
    { value: 'nursinghome', label: 'Nursing Home' },
    { value: 'radiology', label: 'Radiology/Imaging' },
    { value: 'surgical', label: 'Surgical Center' },
    { value: 'skillednursing', label: 'Skilled Nursing Facility' },
    { value: 'other', label: 'Other' },
];

const staffingTypeOptions = [
    { value: 'nursingtravel', label: 'Nursing (Travel)' },
    { value: 'nursingperdiem', label: 'Nursing (Per Diem)' },
    { value: 'allied', label: 'Allied' },
    { value: 'interimleadership', label: 'Interim Leadership' },
    { value: 'nonclinical', label: 'Non Clinical' },
    { value: 'locumtenens', label: 'Locum Tenens' },
    { value: 'other', label: 'Other' },
];

const RequestStaffPage: React.FunctionComponent<IRequestStaffProps> = (
    props,
) => {
    const [formData, setFormData] = useState<RequestStaffFormData>({
        name: '',
        email: '',
        phone: '',
        jobTitle: '',
        organization: '',
        facilityType: '',
        location: '',
        staffingType: [],
    });

    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleFacilityTypeChange = (selectedOption: any) => {
        setFormData({ ...formData, facilityType: selectedOption?.value || '' });
    };

    const handleStaffingTypeChange = (selectedOptions: any) => {
        setFormData({
            ...formData,
            staffingType: selectedOptions.map((option: any) => option.value),
        });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            setError(null);
            setSuccess(false);
            setIsSubmitting(true);
            await apiService.requestStaff(formData);
            setSuccess(true);
            window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
        } catch (err) {
            setError(
                "Huh, that didn't work. Try again, and if it fails, shoot us an email at <a href='mailto:info@x-staffing.com'>info@x-staffing.com</a>.",
            );
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <MDBContainer fluid className="page-padding">
            <MDBRow className="text-center">
                <MDBCol md="6" className="text-left order-md-1 order-2">
                    <p className="sub-heading-text">
                        Building Transparent Workforce Relationships
                    </p>
                    <p className="join-the-movement">Join the Movement</p>
                    <p>
                        We are dedicated to bridging the gap between clinical
                        labor workers and the companies they serve. Our mission
                        is to maximize earnings for workers while reducing
                        overall costs for our clients.
                    </p>
                    <img
                        src={ourMissionImage}
                        className="img-fluid header-image"
                        alt="Our Mission"
                    />
                </MDBCol>
                <MDBCol md="6" className="mb-4 order-md-2 order-1">
                    {!success ? (
                        <>
                            <p className="form-header">
                                Let XStaffing fulfill your hiring needs!
                            </p>
                            <form onSubmit={handleSubmit}>
                                <MDBInput
                                    wrapperClass="mb-4"
                                    id="name"
                                    label="Name *"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    required
                                />
                                <MDBInput
                                    wrapperClass="mb-4"
                                    type="email"
                                    id="email"
                                    label="Email *"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    required
                                />
                                <MDBInput
                                    wrapperClass="mb-4"
                                    type="tel"
                                    id="phone"
                                    label="Phone"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                />
                                <MDBInput
                                    wrapperClass="mb-4"
                                    id="jobTitle"
                                    label="Job Title"
                                    value={formData.jobTitle}
                                    onChange={handleInputChange}
                                />
                                <MDBInput
                                    wrapperClass="mb-4"
                                    id="organization"
                                    label="Name of Organization *"
                                    value={formData.organization}
                                    onChange={handleInputChange}
                                    required
                                />
                                <MDBInput
                                    wrapperClass="mb-4"
                                    id="location"
                                    label="Location (City, State) *"
                                    value={formData.location}
                                    onChange={handleInputChange}
                                    required
                                />
                                <Select
                                    required
                                    options={facilityTypeOptions}
                                    className="mb-4 text-left"
                                    placeholder="Facility Type *"
                                    onChange={handleFacilityTypeChange}
                                    styles={selectStyles}
                                />
                                <Select
                                    isMulti
                                    required
                                    options={staffingTypeOptions}
                                    closeMenuOnSelect={false}
                                    className="mb-4 text-left"
                                    placeholder="Staffing Type *"
                                    onChange={handleStaffingTypeChange}
                                    styles={selectStyles}
                                />
                                <MDBBtn
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="submit-button"
                                >
                                    {isSubmitting ? (
                                        <MDBSpinner
                                            size="sm"
                                            role="status"
                                            tag="span"
                                        />
                                    ) : (
                                        'Submit'
                                    )}
                                </MDBBtn>
                            </form>
                        </>
                    ) : (
                        <div className="success-message">
                            <h3>Thank you for your submission!</h3>
                            <p>
                                A member of the team will be reaching out to you
                                as soon as possible.
                            </p>
                        </div>
                    )}
                    {error && (
                        <div
                            className="submit-error-message"
                            dangerouslySetInnerHTML={{ __html: error }}
                        />
                    )}
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};

export default RequestStaffPage;
