import { StylesConfig } from 'react-select';

// Styles to make the react-select component look as close to MDB components. More could be added here, but this gets the text to match.
// see https://react-select.com/styles#examples
export const selectStyles: StylesConfig = {
    placeholder: (styles) => ({
        ...styles,
        color: 'rgba(0, 0, 0, 0.6)',
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: 1.6,
        fontFamily: "'Roboto', sans-serif",
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 10000, // Ensure the dropdown menu is on top
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: 1000,
    }),
};
